








































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery, AMNumber } from "@/store";
import { download, xlsx } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
      sectionList: [],
      columns: [
        {
          title: "订单号",
          key: "order_no",
          dataIndex: "order_no"
        },
        {
          title: "专属链接",
          key: "code",
          dataIndex: "code",
          customRender: (text: string) =>
            "https://www.angrymiao.com/am-hatsu/number/?code=" + text
        },
        {
          title: "选中号码",
          key: "number",
          dataIndex: "number"
        },
        {
          title: "姓名",
          key: "name",
          dataIndex: "name"
        },
        {
          title: "邮箱",
          key: "email",
          dataIndex: "email"
        },
        {
          title: "更新时间",
          key: "update_time",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center"
        }
      ]
    };
  },
  computed: {
    ...mapState("AMNumbers", {
      list: "list",
      query: "query",
      count: "count"
    })
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .AMNumbersGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering,
        status: (filters.status && filters.status[0]) || this.query.status
      });
    },
    onExport() {
      dispatch.AMNumbersExport().then(res => {
        const data = [
          ["订单号", "选中号码", "姓名", "邮箱", "专属链接", "更新时间"]
        ];
        res.results.map((item: AMNumber) => {
          const row = [
            item.order_no,
            item.number,
            item.name,
            item.email,
            "https://www.angrymiao.com/am-hatsu/number/?code=" + item.code,
            item.update_time
          ];
          data.push(row);
        });
        const file = xlsx([
          {
            name: "选号数据统计",
            data,
            cols: [
              { wch: 70 },
              { wch: 10 },
              { wch: 10 },
              { wch: 30 },
              { wch: 60 },
              { wch: 50 }
            ]
          }
        ]);
        download(file, "选号数据统计.xlsx");
      });
    }
  },
  mounted() {
    this.getList(this.query);
    dispatch.AMNumbersGetSectionList().then(res => {
      this.sectionList = res.results;
    });
    // const orderNos = [
    //   "AM2997",
    //   "AM3000",
    //   "AM3000",
    //   "0xbdab56c45b5d2613cb0f7b838f6f5f5bf5c836c9caa3cdb264f3adc77945a65a",
    //   "AM3003",
    //   "20210829881536587588963200",
    //   "AM3012",
    //   "AM3127",
    //   "AM3384"
    // ];
    // dispatch.AMNumbersPost({
    //   orderNos,
    //   id: 1
    // });
  }
});
