var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"number-list"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('a-divider'),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form}},[(false)?_c('a-form-item',{staticClass:"form-item",attrs:{"label":"选号号段"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['section_id', { initialValue: '' }]),expression:"['section_id', { initialValue: '' }]"}],staticClass:"form-field",staticStyle:{"width":"150px"},on:{"select":_vm.onFilterChange}},_vm._l((_vm.sectionList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.start)+" ~ "+_vm._s(item.end)+" ")])}),1)],1):_vm._e(),_c('div',{staticClass:"actions"},[(false)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onFilterChange}},[_vm._v(" 确认筛选 ")]):_vm._e(),(false)?_c('a-button',{on:{"click":_vm.onFilterReset}},[_vm._v(" 清空条件 ")]):_vm._e(),_c('a-button',{attrs:{"type":"primary","icon":"download"},on:{"click":_vm.onExport}},[_vm._v(" 导出 ")])],1)],1)],1),_c('div',{staticClass:"content"},[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.list,"loading":_vm.loading,"pagination":{
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: function (total) { return ("总计 " + total + " 条"); },
        current: Number(_vm.query.page_number),
        pageSize: Number(_vm.query.page_size),
        total: Number(_vm.count)
      },"rowKey":function (record) { return record.id; },"size":"middle"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"time",fn:function(text){return [_vm._v(" "+_vm._s(_vm._f("timeFormat")(text))+" ")]}},{key:"actions",fn:function(text, record){return [_c('a',{attrs:{"href":'https://www.angrymiao.com/am-hatsu/number/?code=' + record.code,"target":"_blank"}},[_vm._v(" 访问专属链接 ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }